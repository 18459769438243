import { AppBar, Box, Container, Divider, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Link } from "react-router-dom";

function AppLayout({ children }: React.PropsWithChildren<unknown>) {
  const classes = useStyles();
  return (
    <Box>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Link to={`/`} className={classes.link}>
            <img
              alt="Spot Super"
              // eslint-disable-next-line
              src={require("../images/spot-mobile-logo.svg")}
              className={classes.logo}
            />
          </Link>
        </Toolbar>
      </AppBar>
      <Divider />
      <Container style={{ paddingTop: 16 }}>
        <>{children}</>
      </Container>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
  },
  logo: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    height: 44,
  },
}));

export default AppLayout;
