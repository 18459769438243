import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

interface Props {
  title: string;
  value?: string | number;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

function StatTile({ title, value, prefix, suffix }: Props) {
  const classes = useStyles();
  const displayValue =
    typeof value === "number"
      ? value.toLocaleString(undefined, { maximumFractionDigits: 2 })
      : value;
  return (
    <div className={classes.container}>
      <Typography variant="body1">{title}</Typography>
      <div className={classes.content}>
        <Typography variant="h5" className={classes.contentInner}>
          {prefix && <span className={classes.prefix}>{prefix}</span>}
          {displayValue}
          {suffix && <span className={classes.suffix}>{suffix}</span>}
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2)
  },
  content: {
    display: "flex",
    verticalAlign: "middle"
  },
  contentInner: {
    display: "flex",
    alignItems: "center"
  },
  prefix: {
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(1)
  },
  suffix: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1)
  }
}));

export default StatTile;
