import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import { DateTimePickerProps } from "@material-ui/pickers/DateTimePicker";
import * as React from "react";

export interface Props {
  from: DateTimePickerProps["value"];
  to: DateTimePickerProps["value"];
  onFromChange: DateTimePickerProps["onChange"];
  onToChange: DateTimePickerProps["onChange"];
}

function TimeSelect({ from, to, onFromChange, onToChange }: Props) {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item style={{ display: "flex" }}>
        <div className={classes.title}>
          <Typography variant="h6">Select Time Range</Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions} />
      </Grid>
      <Grid item className={classes.input}>
        <DateTimePicker
          label="From"
          value={from}
          onChange={onFromChange}
          clearable
          disableFuture
        />
      </Grid>
      <Grid item className={classes.input}>
        <DateTimePicker
          label="To"
          value={to}
          onChange={onToChange}
          clearable
          disableFuture
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  toolbar: {
    padding: theme.spacing(1)
  },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary,
    textAlign: "right",
    flexShrink: 0
  },
  title: {
    flex: "0 0 auto"
  },
  input: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex"
  }
}));

export default TimeSelect;
