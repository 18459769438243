import { Box, InputBase, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import * as _ from "lodash";
import * as React from "react";
import { FilterButton, RefreshButton, SearchButton } from "../../core";
import { formatToUnits } from "../../lib/formatToUnits";
import {
  default as ReportFeedFilters,
  Props as ReportFeedFiltersProps,
} from "../ReportFeedFilters/ReportFeedFilters";

interface Props extends ReportFeedFiltersProps {
  loading?: boolean;
  total: number;
  onRefresh?: () => void;
  onQueryChange?: (val: unknown) => void;
}

function ReportFeedHeader({
  loading,
  facets,
  total,
  onRefresh,
  onQueryChange,
  status,
  service,
  channel,
  blacklisted,
  onStatusFilterChange,
  onServiceFilterChange,
  onChannelFilterChange,
  onBlacklistedFilterChange,
  onClear,
}: Props) {
  const classes = useStyles();

  const [showSearch, setShowSearch] = React.useState(false);

  function handleSearchClick(_event: React.MouseEvent) {
    if (showSearch) {
      setShowSearch(false);
      if (onQueryChange) {
        onQueryChange(null);
      }
    } else {
      setShowSearch(true);
    }
  }

  function handleQueryChange(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (onQueryChange) {
      onQueryChange(event.currentTarget.value);
    }
  }
  const stringFilters = [status, service, channel];
  const anyStringFiltersPresent = !!_.find(stringFilters, (s) => !_.isEmpty(s));
  const blacklistPresent = blacklisted === true;
  const isFiltered = anyStringFiltersPresent || blacklistPresent;
  const filterButtonColor = isFiltered ? "primary" : "action";
  const filterDesc = [];
  let filterString = "";
  const nameForFacet = (id: string, facetName: string) => {
    const foundFacet = _.find(facets, (f) => f.id === facetName);
    if (foundFacet) {
      const value = _.find(foundFacet.terms, (t) => t.id === id);
      if (value) {
        return value.name ?? "";
      }
    }
    return "";
  };
  if (isFiltered) {
    if (status) {
      filterDesc.push(`Status: ${nameForFacet(status, "statuses")}`);
    }
    if (service) {
      filterDesc.push(`Service: ${nameForFacet(service, "services")}`);
    }
    if (channel) {
      filterDesc.push(`Channel: ${nameForFacet(channel, "channels")}`);
    }
    if (blacklistPresent) {
      filterDesc.push(`Only Blacklisted`);
    }
    filterString = `(${filterDesc.join(", ")})`;
  }
  const subtitle = loading ? (
    <></>
  ) : (
    <Typography variant="subtitle2" id="tableSubtitle">
      {formatToUnits(total)} total {filterString}
    </Typography>
  );

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {!showSearch ? (
          <>
            <Typography variant="h6" id="tableTitle">
              Recent Reports
            </Typography>
            {subtitle}
          </>
        ) : (
          <Box
            className={classes.search}
            bgcolor="background.paper"
            borderRadius={8}
            border={1}
            borderColor="grey.500"
          >
            <SearchIcon color="disabled" />
            <InputBase
              className={classes.input}
              placeholder="Search Reports"
              onChange={handleQueryChange}
            />
          </Box>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <SearchButton onClick={handleSearchClick} disabled={loading} />
        <FilterButton disabled={loading} color={filterButtonColor}>
          <ReportFeedFilters
            facets={facets}
            status={status}
            service={service}
            channel={channel}
            blacklisted={blacklisted}
            onStatusFilterChange={onStatusFilterChange}
            onServiceFilterChange={onServiceFilterChange}
            onChannelFilterChange={onChannelFilterChange}
            onBlacklistedFilterChange={onBlacklistedFilterChange}
            onClear={onClear}
          />
        </FilterButton>
        <RefreshButton onClick={onRefresh} disabled={loading} />
      </div>
    </Toolbar>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    textAlign: "right",
    flexShrink: 0,
  },
  title: {
    flex: "0 0 auto",
  },
  search: {
    padding: "2px 8px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
}));

export default ReportFeedHeader;
