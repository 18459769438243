import * as React from "react";
import { Tenant } from "../api";
import { StatusBadge } from "../core";

interface Props {
  tenant: Tenant;
}

export default function TenantStatusBadge(props: Props) {
  if (!props.tenant.status) {
    return (
      <StatusBadge
        label={`UNREACHABLE HOST: ${props.tenant.host}`}
        status={"error"}
      />
    );
  } else {
    if (props.tenant.version3) {
      return (
        <StatusBadge
          label={
            props.tenant.status.status === "STALE"
              ? `STALE ${props.tenant.status.stale.jobs}/${props.tenant.status.stale.submitted}`
              : props.tenant.status.status
          }
          status={props.tenant.status.status === "OK" ? "success" : "warning"}
        />
      );
    }
  }
  return null;
}
