import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Tenant } from "../api";

export default function TenantLinks({ tenant }: { tenant: Tenant }) {
  const links: Array<{ href: string; name: string }> = [];
  const classes = useStyles();
  if (tenant.version3) {
    if (tenant.status?.url) {
      links.push({ href: `${tenant.status.url}/admin`, name: "Admin" });
      // TODO: Not currently supported in Big Sur
      // links.push({ href: `rdp://full%20address=s:${tenant.status.url.replace(
      //       /^https?:\/\//,
      //       ""
      //     )}:3389&username=s:administrator`, name: "RDP" })
      links.push({
        href: `https://console.aws.amazon.com/ec2/v2/home?region=us-east-1#Instances:search=${tenant.name.toLowerCase()}`,
        name: "EC2",
      });
      links.push({
        href: "https://console.scalegrid.io/Application/mongoclusters",
        name: "ScaleGrid",
      });
    }
  } else {
    links.push({ href: `https://${tenant.host}/admin`, name: "Admin" });
    links.push({
      href: `https://console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/spot-${tenant.id}`,
      name: "Cloudwatch",
    });

    links.push({
      href: `https://dashboard.heroku.com/apps/spot-${tenant.id}`,
      name: "Heroku",
    });

    links.push({
      href: `https://spot-diagnostics.kb.us-east-1.aws.found.io:9243/app/apm/services/${tenant.id}/overview?rangeFrom=now-15m&rangeTo=now&latencyAggregationType=avg`,
      name: "APM",
    });

    if (tenant.status?.rollbarId) {
      const idComponents = tenant.id.split("-");
      const rollbarEnv = idComponents[idComponents.length - 1];
      links.push({
        href: `https://rollbar.com/connectedbits/all/items/?sort=%5Bobject%20Object%5D&status=active&date_from=&date_to=&environments=${rollbarEnv}&activated_to=&framework=&levels=10&levels=20&levels=30&levels=40&levels=50&activated_from=&offset=0&assigned_user=&date_filtering=seen&projects=${tenant.status?.rollbarId}&query=&enc_query=`,
        name: "Rollbar",
      });
    }
    if (tenant.status?.environment) {
      const clientDashboardTitle = escape(
        `${tenant.name} ${tenant.status.environment}`
      );
      links.push({
        href: `https://spot-diagnostics.kb.us-east-1.aws.found.io:9243/app/kibana#/dashboards?title=${clientDashboardTitle}`,
        name: "Client Dashboard",
      });
    }
  }

  return (
    <div className={classes.links}>
      {links.map(({ href, name }, idx) => {
        return (
          <React.Fragment key={name}>
            <Link href={href}>{name}</Link>
            {idx !== links.length - 1 ? " | " : ""}
          </React.Fragment>
        );
      })}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  links: {
    paddingTop: theme.spacing(1),
  },
}));
