import { useQuery } from "@apollo/client";
import { Box, Grid, LinearProgress, Paper, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { QUERY_TENANT, Tenant } from "../api";
import { ReportFeed, StatsDashboard, TenantCard } from "../components";

function TenantPage({ location }: RouteComponentProps) {
  const id = location.pathname.split("/").pop();

  const classes = useStyles();
  const [tab, setTab] = React.useState(0);

  const { data, loading } = useQuery<{ tenant: Tenant }>(QUERY_TENANT, {
    variables: { id },
    skip: !id,
  });

  if (loading) {
    return <LinearProgress color="secondary" />;
  } else if (data?.tenant) {
    const tenant = data.tenant;
    return (
      <Grid container direction="row" className={classes.root} spacing={2}>
        <Grid item className={classes.sidebar}>
          <a href={`http://${tenant.host}`} className={classes.link}>
            <TenantCard tenant={tenant} expanded={true} />
          </a>
        </Grid>
        <Grid item className={classes.content}>
          <Paper className={classes.root}>
            <Box style={{ flex: 1 }}>
              <Tabs
                style={{ flex: 1 }}
                value={tab}
                onChange={(_target, tabIndex) => setTab(tabIndex)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Reports" />
                <Tab label="Stats" />
              </Tabs>
              {tab === 0 && <ReportFeed tenant={tenant} />}
              {tab === 1 && <StatsDashboard tenant={tenant} />}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );
  } else {
    return <div>Not Found</div>;
  }
}

const useStyles = makeStyles((_theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  sidebar: {
    flex: 1,
    justifyContent: "flex-end",
  },
  content: {
    flex: 2,
    minWidth: 320,
  },
  link: {
    textDecoration: "none",
  },
}));

export default withRouter(TenantPage);
