import { useQuery } from "@apollo/client";
import { CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { QUERY_TENANTS, Tenant } from "../../api";
import { TenantCard } from "../TenantCard";
import TenantFilter, { applyFilter, Filter } from "../TenantFilter";

function TenantDeck() {
  const classes = useStyles();
  const { data, loading, refetch } = useQuery<{ tenants: Tenant[] }>(
    QUERY_TENANTS,
    { notifyOnNetworkStatusChange: true, pollInterval: 60_000 }
  );
  const [filter, setFilter] = React.useState<Filter | null>(null);
  const filteredTenants = applyFilter(filter, data?.tenants);
  return (
    <>
      {!loading ? (
        <TenantFilter
          tenants={data?.tenants ?? []}
          onFilterChange={(newFilter) => setFilter(newFilter)}
          onRefresh={() => refetch()}
          filter={filter}
        />
      ) : (
        <CircularProgress />
      )}
      <Grid
        container
        direction="row"
        spacing={1}
        className={classes.deck}
        justify="flex-start"
      >
        {filteredTenants.map((tenant) => (
          <Grid item xs={12} key={tenant.id}>
            <TenantCard
              tenant={tenant}
              expanded={true}
              path={tenant.version3 ? `/tenants/${tenant.id}` : undefined}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  deck: {
    justifyContent: "center",
  },
  link: {
    textDecoration: "none",
    margin: theme.spacing(1),
  },
}));

export default TenantDeck;
