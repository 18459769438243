import { Link } from "@material-ui/core";
import * as React from "react";
import { Tenant } from "../api";

export default function GithubLink({
  tenant,
  children,
}: React.PropsWithChildren<{ tenant: Tenant }>) {
  let href: string | undefined = undefined;
  if (tenant.version3) {
    if (tenant.status?.version) {
      const version = tenant.status?.version;
      const matches = version.match(/^(v?\d+\.\d+)/);
      if (Array.isArray(matches) && matches.length > 0) {
        const firstMatch = matches[0];
        const githubVersion = firstMatch.startsWith("v")
          ? firstMatch
          : `v${firstMatch}`;
        href = `https://github.com/connectedbits/spot3-server/tree/${githubVersion}`;
      }
    }
  } else {
    const commit = tenant.status?.releaseCommit;
    href = `https://github.com/connectedbits/spot-server/tree/${commit}`;
    if (tenant.status?.releaseDeployVersion) {
      const baseBranch =
        tenant.status.releaseDeployVersion === "6.1"
          ? "master"
          : `release-${tenant.status.releaseDeployVersion}`;
      href = `https://github.com/connectedbits/spot-server/compare/${baseBranch}...${commit}`;
    }
  }
  if (href) {
    return <Link href={href}>{children}</Link>;
  }
  return <>{children}</>;
}
