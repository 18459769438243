import { Button, Chip, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import * as React from "react";
import { Tenant } from "../api";

export interface Filter {
  versions: number[] | null;
  environments: string[] | null;
  search: string | null;
}

export function applyFilter(filter: Filter | null, tenants?: Tenant[]) {
  if (!tenants) {
    return [];
  }
  return _.compact(
    tenants.map((t) => {
      if (!filter) {
        return t;
      }
      let matchesVersion = true;
      let matchesEnvironment = true;
      let matchesName = true;
      if (Array.isArray(filter.versions) && filter.versions.length > 0) {
        const matchingVersion = filter.versions.find((v) => {
          if (t.version3) {
            return v === 3;
          } else {
            return String(v) === t.status?.releaseDeployVersion;
          }
        });
        if (!matchingVersion) {
          matchesVersion = false;
        }
      }
      if (
        Array.isArray(filter.environments) &&
        filter.environments.length > 0
      ) {
        const matchingEnvironment = filter.environments.find((e) => {
          if (t.version3) {
            return e === t.status?.env;
          } else {
            return e === t.status?.environment;
          }
        });
        if (!matchingEnvironment) {
          matchesEnvironment = false;
        }
      }
      if (filter.search && filter.search.length > 0) {
        if (
          !t.name.toLowerCase().includes(filter.search.toLowerCase()) &&
          !t.id.toLowerCase().includes(filter.search.toLowerCase())
        ) {
          matchesName = false;
        }
      }
      if (!matchesVersion || !matchesEnvironment || !matchesName) {
        return null;
      }
      return t;
    })
  );
}

interface Props {
  filter?: Filter | null;
  tenants: Tenant[];
  onFilterChange?: (filter: Filter) => void;
  onRefresh?: () => void;
}

export default function TenantFilter(props: Props) {
  const classes = useStyles();
  const allVersions = _.uniq(
    _.compact(
      props.tenants?.map((t) =>
        t.version3 ? "3" : t.status?.releaseDeployVersion
      )
    )
  )
    .map(parseFloat)
    .sort();
  const allEnvironments = _.uniq(
    _.compact(
      props.tenants?.map((t) =>
        t.version3 ? t.status?.env : t.status?.environment
      )
    )
  ).sort();
  const existingFilter = props.filter ?? {
    versions: null,
    environments: null,
    search: null,
  };
  const toggleVersion = (v: number) => {
    const newFilter = _.cloneDeep(existingFilter);
    if (newFilter.versions) {
      if (newFilter.versions.includes(v)) {
        newFilter.versions.splice(newFilter.versions.indexOf(v), 1);
      } else {
        newFilter.versions.push(v);
      }
    } else {
      newFilter.versions = [v];
    }
    return newFilter;
  };
  const toggleEnvironment = (e: string) => {
    const newFilter = _.cloneDeep(existingFilter);
    if (newFilter.environments) {
      if (newFilter.environments.includes(e)) {
        newFilter.environments.splice(newFilter.environments.indexOf(e), 1);
      } else {
        newFilter.environments.push(e);
      }
    } else {
      newFilter.environments = [e];
    }
    return newFilter;
  };
  return (
    <Grid container direction={"row"} spacing={2} className={classes.root}>
      <Grid item key={"search"}>
        <TextField
          size={"small"}
          autoCapitalize={"off"}
          autoCorrect={"off"}
          label={"Search"}
          variant="outlined"
          value={props.filter?.search ?? ""}
          onChange={(e) => {
            const newFilter = _.cloneDeep(existingFilter);
            if (e.target.value && e.target.value.length > 0) {
              newFilter.search = e.target.value;
            } else {
              newFilter.search = null;
            }
            props.onFilterChange?.(newFilter);
          }}
        />
      </Grid>
      <Grid item className={classes.chipStrip} key={"version"}>
        Version:{" "}
        {allVersions.map((v) => (
          <Chip
            className={classes.chip}
            size={"small"}
            label={v}
            key={v}
            onClick={() => {
              props.onFilterChange?.(toggleVersion(v));
            }}
            color={props.filter?.versions?.includes(v) ? "primary" : undefined}
          />
        ))}
      </Grid>
      <Grid item className={classes.chipStrip} key={"environment"}>
        Environment:{" "}
        {allEnvironments.map((e) => (
          <Chip
            className={classes.chip}
            key={e}
            size={"small"}
            label={e}
            color={
              props.filter?.environments?.includes(e) ? "primary" : undefined
            }
            onClick={() => {
              props.onFilterChange?.(toggleEnvironment(e));
            }}
          />
        ))}
      </Grid>
      <Grid item key={"refresh"}>
        <Button variant="contained" onClick={props?.onRefresh}>
          Refresh
        </Button>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  chip: {
    marginLeft: theme.spacing(0.2),
    marginRight: theme.spacing(0.2),
  },
  chipStrip: {
    marginTop: "auto",
    marginBottom: "auto",
  },
}));
