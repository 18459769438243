import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(3),
    paddingTop: 8 * 20,
  },
  logo: {
    maxWidth: 600,
  },
  container: {
    marginTop: theme.spacing(2),
  },
}));

const LandingPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        // eslint-disable-next-line
        src={require("../images/spot-mobile-logo.svg")}
        className={classes.logo}
        alt="Spot Mobile"
      />
      <Box className={classes.container}>
        <Typography variant="h4" gutterBottom>
          Not Found
        </Typography>
      </Box>
    </div>
  );
};

export default LandingPage;
