import { makeStyles, Toolbar, Typography } from "@material-ui/core";
import { format, formatDistanceStrict, isToday } from "date-fns";
import { differenceInHours } from "date-fns/esm";
import * as moment from "moment";
import * as React from "react";
import { RefreshButton } from "../../core";
import { TimeSelectButton } from "../TimeSelect";
import TimeSelect, { Props as TimeSelectProps } from "../TimeSelect/TimeSelect";

interface Props {
  loading: boolean;
  onRefresh?: () => void;
  from: TimeSelectProps["from"];
  to: TimeSelectProps["to"];
  onFromChange: TimeSelectProps["onFromChange"];
  onToChange: TimeSelectProps["onToChange"];
}

function labelForInterval(
  from: TimeSelectProps["from"],
  to: TimeSelectProps["to"]
) {
  let label: React.ReactNode = "Today's Stats";
  let intervalLabel: React.ReactNode = "In last day";
  const fromDate = moment(from ?? new Date()).toDate();
  const toDate = moment(to ?? new Date()).toDate();
  const diff = differenceInHours(toDate, fromDate);
  if (diff === 24 && isToday(toDate)) {
    return [label, intervalLabel];
  } else if (!isToday(fromDate) || !isToday(toDate)) {
    const fromTime = (
      <time dateTime={fromDate.toISOString()} title={fromDate.toISOString()}>
        {format(fromDate, "MMM do, yyyy p")}
      </time>
    );
    const toTime = (
      <time dateTime={toDate.toISOString()} title={toDate.toISOString()}>
        {format(toDate, "MMM do, yyyy p")}
      </time>
    );
    const intervalIndicator = (
      <>
        {fromTime} to {toTime}
      </>
    );
    const strictDistance = formatDistanceStrict(toDate, fromDate);
    if (isToday(toDate)) {
      label = <>In last {strictDistance}</>;
      intervalLabel = intervalIndicator;
    } else {
      label = <>In {strictDistance}</>;
      intervalLabel = intervalIndicator;
    }
  }
  return [label, intervalLabel];
}

function StatsDashboardHeader({
  loading,
  onRefresh,
  from,
  to,
  onFromChange,
  onToChange,
}: Props) {
  const classes = useStyles();

  function handleRefresh(_event: React.MouseEvent) {
    if (onRefresh) {
      onRefresh();
    }
  }
  const [label, intervalLabel] = labelForInterval(from, to);
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {label}
        </Typography>
        <Typography variant="subtitle2" id="tableSubtitle">
          {intervalLabel}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <TimeSelectButton disabled={loading}>
          <TimeSelect
            from={from}
            to={to}
            onFromChange={onFromChange}
            onToChange={onToChange}
          />
        </TimeSelectButton>
        <RefreshButton onClick={handleRefresh} disabled={loading} />
      </div>
    </Toolbar>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    textAlign: "right",
    flexShrink: 0,
  },
  title: {
    flex: "0 0 auto",
  },
  search: {
    padding: "2px 8px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
}));

export default StatsDashboardHeader;
