import { ApolloProvider } from "@apollo/client";
import MomentUtils from "@date-io/moment";
import { CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import { client, theme } from "./config";
import {
  AppLayout,
  HomePage,
  NotFoundPage,
  SignInPage,
  TenantPage,
} from "./pages";

interface Props extends RouteProps {
  component: React.ComponentType<any>;
  authed?: boolean;
}

function ProtectedRoute({ component: Component, authed, ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const token = window.localStorage.getItem("token");
        const isAuthenticated = token && token.length > 0;
        if (isAuthenticated) {
          return (
            <AppLayout>
              <Component {...props} />
            </AppLayout>
          );
        } else {
          return (
            <Redirect
              to={{ pathname: "/signin", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
}

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Switch>
                <Route path="/signin" component={SignInPage} />
                <ProtectedRoute path="/tenants/:id" component={TenantPage} />
                <ProtectedRoute component={HomePage} />
                <Route component={NotFoundPage} />
              </Switch>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

let hot = <T extends any>(klass: T) => klass;
if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  hot = require("react-hot-loader/root").hot;
}
export default hot(App);
