import { IconButton, Popover, Tooltip } from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TimeIcon from "@material-ui/icons/AccessTime";
import * as React from "react";

const label = "Time Select";

interface Props {
  disabled?: IconButtonProps["disabled"];
}

function TimeSelectButton({
  children,
  disabled
}: React.PropsWithChildren<Props>) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const showPopup = Boolean(anchorEl);
  const id = showPopup ? "simple-popover" : undefined;

  function handleClick(event: React.MouseEvent) {
    setAnchorEl(event.currentTarget);
  }

  function handleHideForm() {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <Tooltip title={label}>
        <>
          <IconButton
            aria-label={label}
            onClick={handleClick}
            className={classes.button}
            disabled={disabled}
          >
            <TimeIcon />
          </IconButton>
        </>
      </Tooltip>
      <Popover
        id={id}
        open={showPopup}
        anchorEl={anchorEl}
        onClose={handleHideForm}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {children}
      </Popover>
    </React.Fragment>
  );
}

const useStyles = makeStyles(_theme => ({
  button: {}
}));

export default TimeSelectButton;
