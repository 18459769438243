import { Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Tenant } from "../../api";
import GithubLink from "../GithubLink";
import TenantLinks from "../TenantLinks";
interface Props {
  tenant: Tenant;
}

function TenantCardInfo({ tenant }: Props) {
  const classes = useStyles();
  if (!tenant.status) {
    return <></>;
  }
  const info: Array<{
    id: string;
    label: string;
    value: string | number | undefined | null | JSX.Element;
  }> = [];
  if (tenant.version3) {
    info.push(
      ...[
        { id: "host", label: "Host", value: tenant.status.host },
        {
          id: "version",
          label: "Version",
          value: (
            <GithubLink tenant={tenant}>{tenant.status.version}</GithubLink>
          ),
        },
      ]
    );
    if (tenant.status.status === "STALE") {
      info.push({
        id: "stale-jobs",
        label: "Stale Jobs",
        value: tenant.status.stale.jobs,
      });
      info.push({
        id: "stale-submissions",
        label: "Stale Submissions",
        value: tenant.status.stale.submitted,
      });
    }
  } else {
    info.push(...[{ id: "host", label: "Host", value: tenant.host }]);
    if (tenant.status.releaseBranch) {
      info.push({
        id: "branch",
        label: "Branch",
        value: (
          <GithubLink tenant={tenant}>{tenant.status.releaseBranch}</GithubLink>
        ),
      });
    }
    const lastDay = tenant.status.stats?.tickets?.opened?.find(
      (m) => m.duration === 86400
    );
    if (lastDay) {
      info.push({
        id: "tickets-opened",
        label: "Tickets Opened (Last 24h)",
        value: lastDay.count,
      });
    }
    if (tenant.status.stats?.jobs?.all) {
      if (tenant.status.stats.jobs.all > 0) {
        info.push({
          id: "jobs-all",
          label: "All Jobs",
          value: tenant.status.stats.jobs.all,
        });
      }
    }
    if (tenant.status.stats?.jobs?.error) {
      if (tenant.status.stats.jobs.error > 0) {
        info.push({
          id: "jobs-error",
          label: "Errored Jobs",
          value: tenant.status.stats.jobs.error,
        });
      }
    }
    const ticketSync = tenant.status.stats?.sync?.find(
      (st) => st.model === "Ticket"
    );

    if (ticketSync?.warnings) {
      info.push({
        id: "tickets-sync-warnings",
        label: "Ticket Sync Warnings",
        value: ticketSync.warnings,
      });
    }
    if (ticketSync?.error) {
      info.push({
        id: "tickets-sync-error",
        label: "Ticket Sync Errors",
        value: ticketSync.error,
      });
    }
    const platforms = tenant.status.stats?.platforms;
    if (Array.isArray(platforms)) {
      const lastDayPlatforms = platforms.filter((p) => p.duration === 86400);
      if (lastDayPlatforms.length > 0) {
        info.push({
          id: "client-platforms",
          label: "Platforms (Last 24h)",
          value: (
            <ul>
              {lastDayPlatforms.map((metric) => (
                <li key={metric.platform}>
                  {metric.platform}: {metric.count}
                </li>
              ))}
            </ul>
          ),
        });
      }
    }
    const versions = tenant.status.stats?.versions;
    if (Array.isArray(versions)) {
      const lastDayVersions = versions.filter((v) => v.duration === 86400);
      if (lastDayVersions.length > 0) {
        info.push({
          id: "client-versions",
          label: "Versions (Last 24h)",
          value: (
            <ul>
              {lastDayVersions.map((metric) => (
                <li key={metric.version}>
                  {metric.version}: {metric.count}
                </li>
              ))}
            </ul>
          ),
        });
      }
    }
  }

  return (
    <div>
      <Grid container direction="column" className={classes.text} spacing={1}>
        {info.map((statusInfo) => (
          <Grid
            container
            direction="row"
            className={classes.item}
            spacing={1}
            key={statusInfo.id}
          >
            <Grid item className={classes.label} key="label">
              <Typography variant="subtitle2">{statusInfo.label}</Typography>
            </Grid>
            <Grid item className={classes.value} key="value">
              <Typography variant="subtitle2" color="textSecondary">
                {statusInfo.value}
              </Typography>
            </Grid>
            <Divider />
          </Grid>
        ))}
      </Grid>
      <TenantLinks tenant={tenant} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    display: "flex",
    border: "1px solid #ccc",
    borderRadius: "5px!important",
    backgroundColor: "#eee",
  },
  item: {
    display: "flex",
    padding: theme.spacing(0.5),
  },
  label: { flex: 1 },
  value: { flex: 2 },
}));

export default TenantCardInfo;
