import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { TenantDeck } from "../components";

function HomePage() {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <TenantDeck />
    </Grid>
  );
}

const useStyles = makeStyles(_theme => ({
  root: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default HomePage;
