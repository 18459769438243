if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  require("react-hot-loader");
}
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";

const root = <App />;

ReactDOM.render(root, document.getElementById("root"));
