import { Box, CardMedia, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

interface Props {
  report: {
    submittedPhoto?: string | null;
    closedPhoto?: string | null;
    description?: string | null;
  };
}

function ReportTileMedia(props: Props) {
  const { report } = props;
  const classes = useStyles();
  const submittedThumbnail = report.submittedPhoto;
  const closedThumbnail = report.closedPhoto;

  return (
    <Box className={classes.root}>
      {submittedThumbnail && (
        <CardMedia className={classes.cover} image={submittedThumbnail} />
      )}
      <div className={classes.details}>
        <div className={classes.content}>
          <Typography variant="body1">{report.description}</Typography>
        </div>
      </div>
      {closedThumbnail && (
        <CardMedia className={classes.cover} image={closedThumbnail} />
      )}
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    border: "1px solid #ccc",
    borderRadius: "5px!important",
    padding: theme.spacing(1.5),
    backgroundColor: "#eee"
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  content: {
    flex: "1 0 auto",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  cover: {
    minHeight: 100,
    minWidth: 100,
    width: 0
  }
}));

export default ReportTileMedia;
