import { useQuery } from "@apollo/client";
import { Divider, Grid, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { QUERY_REPORTS, Tenant } from "../../api";
import { useDebounce } from "../../lib";
import { default as ReportTile } from "../ReportTile/ReportTile";
import ReportFeedFooter from "./ReportFeedFooter";
import ReportFeedHeader from "./ReportFeedHeader";

interface Props {
  tenant: { id: string };
}

function ReportFeed({ tenant }: Props) {
  const classes = useStyles();

  const [query, setQuery] = React.useState<string | null>(null);
  const debouncedQuery = useDebounce(query, 500);
  const [status, setStatus] = React.useState<string | null>(null);
  const [service, setService] = React.useState<string | null>(null);
  const [channel, setChannel] = React.useState<string | null>(null);
  const [blacklisted, setBlacklisted] = React.useState<boolean | null>(null);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const { data, loading, refetch } = useQuery<{ tenant: Tenant }>(
    QUERY_REPORTS,
    {
      variables: {
        tenantID: tenant.id,
        q: debouncedQuery,
        page,
        pageSize,
        status,
        service,
        channel,
        blacklisted,
      },
      skip: !tenant,
      pollInterval: 120000,
      fetchPolicy: "cache-and-network",
    }
  );
  const reports = data?.tenant?.reports?.reports;
  const curPage = data?.tenant?.reports?.page ?? 0;
  const curPageSize = data?.tenant?.reports?.pageSize ?? 0;
  const curTotal = data?.tenant?.reports?.total ?? 0;
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <ReportFeedHeader
          loading={loading}
          facets={data?.tenant?.reports?.facets ?? []}
          status={status}
          total={data?.tenant?.reports?.total ?? 0}
          service={service}
          channel={channel}
          blacklisted={blacklisted}
          onQueryChange={(q) => setQuery(String(q))}
          onStatusFilterChange={(value) => setStatus(String(value))}
          onServiceFilterChange={(value) => setService(String(value))}
          onChannelFilterChange={(value) => setChannel(String(value))}
          onBlacklistedFilterChange={(value) => {
            setBlacklisted(!!value);
          }}
          onClear={() => {
            setStatus(null);
            setService(null);
            setChannel(null);
            setBlacklisted(null);
          }}
          onRefresh={() => refetch()}
        />
        <Divider />
      </Grid>
      <Grid item>
        {loading && <LinearProgress color="secondary" />}
        {Array.isArray(reports) &&
          reports.map((report, index) => (
            <div className={classes.item} key={report.ticket ?? index}>
              <ReportTile report={report} />
              <Divider />
            </div>
          ))}
      </Grid>
      <Grid item>
        {!loading && data && data.tenant?.reports && (
          <ReportFeedFooter
            page={curPage - 1}
            pageSize={curPageSize}
            total={curTotal}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSizes) => setPageSize(newPageSizes)}
          />
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  item: {
    padding: theme.spacing(0),
  },
  divider: {},
}));

export default ReportFeed;
