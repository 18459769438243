import * as Colors from "@material-ui/core/colors";
import * as React from "react";
import { Tenant } from "../api";
import Box from "./Box";

interface Props {
  tenant: Tenant;
}
export default function VersionChip(props: Props) {
  const version = props.tenant.version3
    ? "3"
    : props.tenant.status?.releaseDeployVersion;
  if (version) {
    let backgroundColor: string = Colors.green[50];
    let foregroundColor: string = Colors.green[900];
    if (version === "3") {
      backgroundColor = Colors.lime[50];
      foregroundColor = Colors.lime[900];
    } else if (version === "5") {
      backgroundColor = Colors.lightGreen[50];
      foregroundColor = Colors.lightGreen[900];
    } else if (version === "6") {
      foregroundColor = Colors.green[800];
    }
    return (
      <Box foregroundColor={foregroundColor} backgroundColor={backgroundColor}>
        VERSION: {version}
      </Box>
    );
  }
  return null;
}
