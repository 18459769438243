import * as Colors from "@material-ui/core/colors";
import * as moment from "moment";
import * as React from "react";
import { Tenant } from "../api";
import Box from "./Box";

interface Props {
  tenant: Tenant;
}
export default function UpdatedChip(props: Props) {
  if (!props.tenant.version3) {
    return null;
  }
  const updatedAt = props.tenant.status?.updatedAt;
  if (!updatedAt) {
    return null;
  }
  const backgroundColor: string = Colors.grey[50];
  const foregroundColor: string = Colors.grey[900];

  const updateMoment = moment(updatedAt);
  const updateAgoStr = updateMoment.fromNow();
  const updateTimeStr = updateMoment.format();

  return (
    <Box foregroundColor={foregroundColor} backgroundColor={backgroundColor}>
      UPDATED:{" "}
      <time dateTime={updateTimeStr} title={updateTimeStr}>
        {updateAgoStr}
      </time>
    </Box>
  );
}
