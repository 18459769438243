import * as Colors from "@material-ui/core/colors";
import md5 from "blueimp-md5";
import * as moment from "moment";
import * as React from "react";
import { Tenant } from "../api";
import Box from "./Box";

interface Props {
  tenant: Tenant;
}
export default function DeployChip(props: Props) {
  if (props.tenant.version3) {
    return null;
  }
  const deployedBy = props.tenant.status?.releaseDeployedBy;
  const deployedAt = props.tenant.status?.releaseTime;
  const deployCommit = props.tenant.status?.releaseCommit;
  if (!deployedBy && !deployedAt && !deployCommit) {
    return null;
  }
  const backgroundColor: string = Colors.grey[50];
  const foregroundColor: string = Colors.grey[900];
  let deployTimeElement: JSX.Element | null = null;
  let deployedByElement: JSX.Element | null = null;
  let deployCommitElement: JSX.Element | null = null;
  if (deployCommit) {
    deployCommitElement = (
      <>
        <a
          href={`http://github.com/connectedbits/spot-server/tree/${deployCommit}`}
        >
          {deployCommit.substr(0, 6)}
        </a>{" "}
      </>
    );
  }
  if (deployedAt) {
    const deployMoment = moment(deployedAt);
    const deployAgoStr = deployMoment.fromNow();
    const deployTimeStr = deployMoment.format();
    deployTimeElement = (
      <time dateTime={deployTimeStr} title={deployTimeStr}>
        {deployAgoStr}
      </time>
    );
  }
  if (deployedBy) {
    deployedByElement = (
      <span>
        <img
          src={`https://www.gravatar.com/avatar/${md5(deployedBy)}`}
          style={{ maxHeight: 10 }}
        />{" "}
        {deployedBy}
      </span>
    );
  }
  const joiner = deployedAt && deployedBy ? " by " : null;
  return (
    <Box foregroundColor={foregroundColor} backgroundColor={backgroundColor}>
      DEPLOY: {deployCommitElement} {deployTimeElement} {joiner}{" "}
      {deployedByElement}
    </Box>
  );
}
