import { Box, TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

interface Props {
  total?: number;
  page?: number;
  pageSize?: number;
  onPageChange?: (newPage: number) => void;
  onPageSizeChange?: (newPageSize: number) => void;
}

function ReportFeedFooter({
  total,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
}: Props) {
  const classes = useStyles();

  function handlePageChange(_event: React.MouseEvent | null, value: number) {
    if (onPageChange) {
      onPageChange(value);
    }
  }

  function handlePageSizeChange(
    _event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    reactElement: React.DOMElement<any, any>
  ) {
    if (onPageSizeChange) {
      const key = String(reactElement.key ?? "0").replace(/[^\d]/g, "");
      onPageSizeChange(parseInt(key, 10));
    }
  }
  return (
    <Box className={classes.root}>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        count={total ?? 0}
        rowsPerPage={pageSize ?? 10}
        page={page ?? 0}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange as any}
        {...{ component: "div" }}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

export default ReportFeedFooter;
