import { useQuery } from "@apollo/client";
import { Divider, Grid, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import * as React from "react";
import { QUERY_STATS, Stat, Tenant } from "../../api";
import { StatTile } from "../../core";
import StatsDashboardHeader from "./StatsDashboardHeader";

interface Props {
  tenant: { id: string };
}

function StatsDashboard({ tenant }: Props) {
  const classes = useStyles();
  const now = new Date();
  const yesterday = new Date(now.getTime() - 1000 * 60 * 60 * 24);

  const [from, setFrom] = React.useState<moment.Moment | null>(
    moment(yesterday)
  );
  const [to, setTo] = React.useState<moment.Moment | null>(moment(now));

  const { data, loading, refetch } = useQuery<{ tenant: Tenant }>(QUERY_STATS, {
    variables: { tenantID: tenant.id, from, to },
    skip: !tenant,
    pollInterval: 120000,
    fetchPolicy: "cache-and-network",
  });

  function handleRefresh() {
    void refetch();
  }

  const stats: Stat | null = data?.tenant?.stats ?? null;

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <StatsDashboardHeader
          loading={loading}
          from={from}
          to={to}
          onFromChange={(value) => {
            setFrom(value);
            void refetch();
          }}
          onToChange={(value) => {
            setTo(value);
            void refetch();
          }}
          onRefresh={handleRefresh}
        />
        <Divider />
      </Grid>
      <Grid item>
        {loading && <LinearProgress color="secondary" />}
        {stats && (
          <Grid container direction="column" style={{ paddingTop: 16 }}>
            <Grid item className={classes.stats}>
              <Typography variant="subtitle2" gutterBottom>
                Tickets
              </Typography>
              <Grid container direction="row" spacing={1}>
                <Grid item className={classes.stat}>
                  <StatTile title="Submitted" value={stats.submitted} />
                </Grid>
                <Grid item className={classes.stat}>
                  <StatTile title="External" value={stats.external} />
                </Grid>
                <Grid item className={classes.stat}>
                  <StatTile title="Updated" value={stats.updated} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.stats}>
              <Typography variant="subtitle2" gutterBottom>
                Reporters
              </Typography>
              <Grid container direction="row" spacing={1}>
                <Grid item className={classes.stat}>
                  <StatTile title="Registered" value={stats.registered} />
                </Grid>
                <Grid item className={classes.stat}>
                  <StatTile title="Visits" value={stats.visited} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.stats}>
              <Typography variant="subtitle2" gutterBottom>
                Channels
              </Typography>
              <Grid container direction="row" spacing={1}>
                {stats.channels.map((c) => (
                  <Grid item className={classes.stat} key={c.id}>
                    <StatTile title={c.name} value={c.value} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    padding: theme.spacing(1),
  },
  stats: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0),
  },
  stat: {},
}));

export default StatsDashboard;
