import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import * as Colors from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Link } from "react-router-dom";
import { Tenant } from "../../api";
import DeployChip from "../DeployChip";
import EnvironmentChip from "../EnvironmentChip";
import TenantStatusBadge from "../TenantStatusBadge";
import UpdatedChip from "../UpdatedChip";
import VersionChip from "../VersionChip";
import TenantCardInfo from "./TenantCardInfo";

interface Props {
  tenant: Tenant;
  path?: string;
  expanded?: boolean;
}

function TenantCard({ tenant, expanded, path }: Props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      {tenant.hero ? (
        <CardMedia
          className={classes.media}
          image={tenant.hero}
          title={tenant.name ?? ""}
        />
      ) : (
        <div className={classes.media} />
      )}
      <div className={classes.action}>
        <CardContent className={classes.content}>
          <CardActionArea>
            <Typography variant="h6" component="h3">
              {path ? (
                <Link to={{ pathname: path }}>{tenant.name}</Link>
              ) : (
                tenant.name
              )}
            </Typography>
            <span className={classes.subtitle}>{tenant.id}</span>
          </CardActionArea>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <VersionChip tenant={tenant} />
            </Grid>
            <Grid item>
              <EnvironmentChip tenant={tenant} />
            </Grid>
            {tenant.version3 ? (
              <>
                <Grid item>
                  <TenantStatusBadge tenant={tenant} />
                </Grid>
                <Grid item>
                  <UpdatedChip tenant={tenant} />
                </Grid>
              </>
            ) : null}
            {!tenant.version3 ? (
              <>
                <Grid item>
                  <DeployChip tenant={tenant} />
                </Grid>
                <Grid item>
                  <TenantStatusBadge tenant={tenant} />
                </Grid>
              </>
            ) : null}
          </Grid>
          {expanded && (
            <div className={classes.info}>
              <TenantCardInfo tenant={tenant} />
            </div>
          )}
        </CardContent>
      </div>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 320,
    display: "flex",
  },
  media: {
    minHeight: "100%",
    width: "20%",
  },
  action: {
    width: "80%",
  },
  info: {
    flex: "1 0 auto",
    paddingTop: theme.spacing(2),
  },
  status: {
    alignItems: "center",
  },
  subtitle: {
    paddingBottom: theme.spacing(0.4),
    display: "inline-box",
    color: Colors.grey[800],
  },
  progress: {},
  content: {},
}));

export default TenantCard;
