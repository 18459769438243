import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import * as React from "react";
import { Facet, Term } from "../../api";
import { StatusBadge } from "../../core";

export interface Props {
  facets?: Facet[];
  status?: string | null;
  service?: string | null;
  channel?: string | null;
  blacklisted?: boolean | null;
  onStatusFilterChange?: (value: unknown) => void;
  onServiceFilterChange?: (value: unknown) => void;
  onChannelFilterChange?: (value: unknown) => void;
  onBlacklistedFilterChange?: (value: unknown) => void;
  onClear?: () => void;
}

function ReportFeedFilters({
  facets,
  status,
  service,
  channel,
  blacklisted,
  onStatusFilterChange,
  onServiceFilterChange,
  onChannelFilterChange,
  onBlacklistedFilterChange,
  onClear,
}: Props) {
  const classes = useStyles();

  function renderFacetTermMenuItem(facet: Facet, term: Term) {
    switch (facet.id) {
      case "statuses":
        return <StatusBadge label={term.name} status={term.id} />;
      case "services":
        return term.name;
      default:
        return term.name;
    }
  }

  function handleFacetChange(facet: Facet, value: unknown) {
    switch (facet.id) {
      case "statuses":
        if (onStatusFilterChange) {
          onStatusFilterChange(value);
        }

        break;
      case "services":
        if (onServiceFilterChange) {
          onServiceFilterChange(value);
        }
        break;
      case "channels":
        if (onChannelFilterChange) {
          onChannelFilterChange(value);
        }
        break;
      case "blacklisted":
        if (onBlacklistedFilterChange) {
          onBlacklistedFilterChange(value);
        }
        break;
      default:
        return;
    }
  }

  function valueForFacet(facet: Facet) {
    switch (facet.id) {
      case "statuses":
        return status;
      case "services":
        return service;
      case "channels":
        return channel;
      case "blacklisted":
        return blacklisted;
      default:
        return null;
    }
  }

  function renderSelectFacet(facet?: Facet) {
    if (!facet) {
      return;
    }
    return (
      <Grid item className={classes.facet} key={facet.id}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor={facet.id}>
            {facet.id.charAt(0).toUpperCase() + facet.id.slice(1)}
          </InputLabel>
          <Select
            value={valueForFacet(facet) ?? ""}
            onChange={(event) =>
              handleFacetChange(facet, event.target.value as any)
            }
            input={<Input name={facet.id} id={facet.id} />}
            className={classes.selectEmpty}
          >
            <MenuItem value="" key="all">
              All
            </MenuItem>
            {facet.terms.map((term) => (
              <MenuItem value={term.id} key={term.id}>
                {renderFacetTermMenuItem(facet, term)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  function renderCheckboxFacet(facet?: Facet) {
    if (!facet) {
      return;
    }
    return (
      <Grid item className={classes.facet} key={facet.id}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!(valueForFacet(facet) ?? false)}
                onChange={(event) =>
                  handleFacetChange(facet, event.target.checked)
                }
                value={valueForFacet(facet) ?? false}
              />
            }
            label={facet.id.charAt(0).toUpperCase() + facet.id.slice(1)}
          />
        </FormGroup>
      </Grid>
    );
  }
  if (!Array.isArray(facets)) {
    facets = [];
  }
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item style={{ display: "flex", verticalAlign: "middle" }}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
            <Typography variant="h6">Filters</Typography>
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            <Tooltip title="Clear">
              <>
                <IconButton
                  aria-label="Clear"
                  onClick={onClear}
                  className={classes.button}
                  disabled={!status && !service && !channel && !blacklisted}
                >
                  <ClearIcon />
                </IconButton>
              </>
            </Tooltip>
          </div>
        </Toolbar>
      </Grid>
      <Grid item className={classes.facets}>
        {renderSelectFacet(facets.find((f) => f.id === "statuses"))}
        {renderSelectFacet(facets.find((f) => f.id === "services"))}
        {renderSelectFacet(facets.find((f) => f.id === "channels"))}
        {renderCheckboxFacet(facets.find((f) => f.id === "blacklisted"))}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    flexGrow: 1,
  },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    textAlign: "right",
    flexShrink: 0,
  },
  title: {
    flex: "0 0 auto",
  },
  facets: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  facet: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
  },
  formControl: {
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {},
}));

export default ReportFeedFilters;
