import gql from "graphql-tag";

interface BaseTenant {
  id: string;
  name: string;
  color: string;
  icon: string | null;
  latitude: number | null;
  longitude: number | null;
  host: string | null;
  hero: string | null;
  stats: Stat | null;
  reports: {
    total: number | null;
    page: number | null;
    pageSize: number | null;
    facets: Facet[] | null;
    reports: Report[] | null;
  } | null;
}

interface Spot3Tenant extends BaseTenant {
  version3: true;
  status: Spot3Status | null;
}

interface SpotTenant extends BaseTenant {
  version3: false;
  status: SpotStatus | null;
}

export type Tenant = SpotTenant | Spot3Tenant;

export interface Term {
  id: string;
  name?: string | null;
  count: number;
}
export interface Facet {
  id: string;
  total: number;
  terms: Term[];
}

export interface Report {
  id: string;
  ticket: string | null;
  serviceID: string;
  serviceName: string;
  shared: boolean;
  blacklisted: boolean;
  status: string;
  statusLabel: string | null;
  statusMessage: string | null;
  statusUpdatedAt: string;
  openedAt: string | null;
  closedAt: string | null;
  description: string | null;
  channel: string | null;
  submittedPhoto: string | null;
  closedPhoto: string | null;
  latitude: number | null;
  longitude: number | null;
  address: string | null;
}

export interface Channel {
  id: string;
  name: string;
  value: number;
}
export interface Stat {
  submitted: number;
  external: number;
  updated: number;
  registered: number;
  visited: number;
  channels: Channel[];
}

export interface Spot3Status {
  updatedAt: string;
  account: string;
  env: string;
  name: string;
  host: string;
  url: string;
  version: string;
  status: string;
  stale: {
    jobs: number;
    submitted: number;
  };
}

export interface SpotStatus {
  utcOffset?: number;
  appId?: string;
  environment?: string;
  releaseDescription?: string;
  releaseVersion?: string;
  releaseCommit?: string;
  releaseTime?: string;
  releaseDeployVersion?: string;
  releaseBranch?: string;
  releaseDeployedBy?: string;
  rollbarId?: number;
  stats?: {
    tickets?: { opened?: Array<{ duration?: number; count?: number }> };
    jobs?: { all?: number; error?: number };
    versions?: Array<{ version?: string; duration?: number; count?: number }>;
    platforms?: Array<{
      platform?: string;
      duration?: number;
      count?: number;
    }>;
    sync?: Array<{
      model?: string;
      total?: number;
      error?: number;
      stale?: number;
      synced?: number;
      warnings?: number;
    }>;
  };
}

export const QUERY_TENANTS = gql`
  query tenants {
    tenants {
      id
      name
      color
      icon
      latitude
      longitude
      host
      hero
      version3
      status {
        ... on Spot3Status {
          updatedAt
          account
          env
          name
          host
          url
          version
          status
          stale {
            jobs
            submitted
          }
        }
        ... on SpotStatus {
          utcOffset
          appId
          environment
          releaseDescription
          releaseVersion
          releaseCommit
          releaseTime
          releaseDeployVersion
          releaseBranch
          releaseDeployedBy
          rollbarId
          stats {
            tickets {
              opened {
                duration
                count
              }
            }
            jobs {
              all
              error
            }
            versions {
              version
              duration
              count
            }
            platforms {
              platform
              duration
              count
            }
            sync {
              model
              total
              error
              stale
              synced
              warnings
            }
          }
        }
      }
    }
  }
`;

export const QUERY_TENANT = gql`
  query tenant($id: ID!) {
    tenant(id: $id) {
      id
      name
      color
      icon
      latitude
      longitude
      host
      hero
      version3
      status {
        ... on Spot3Status {
          updatedAt
          account
          env
          name
          host
          url
          version
          status
          stale {
            jobs
            submitted
          }
        }
        ... on SpotStatus {
          utcOffset
          appId
          environment
          releaseDescription
          releaseVersion
          releaseCommit
          releaseTime
          releaseDeployVersion
          releaseBranch
          releaseDeployedBy
          rollbarId
          stats {
            tickets {
              opened {
                duration
                count
              }
            }
            versions {
              version
              duration
              count
            }
            platforms {
              platform
              duration
              count
            }
            jobs {
              all
              error
            }
            sync {
              model
              total
              error
              stale
              synced
              warnings
            }
          }
        }
      }
    }
  }
`;

export const QUERY_STATS = gql`
  query stats($tenantID: ID!, $from: String, $to: String) {
    tenant(id: $tenantID) {
      stats(from: $from, to: $to) {
        submitted
        external
        updated
        registered
        visited
        channels {
          id
          name
          value
        }
      }
    }
  }
`;

export const QUERY_REPORTS = gql`
  query reports(
    $tenantID: ID!
    $q: String
    $page: Int
    $pageSize: Int
    $status: String
    $service: String
    $channel: String
    $blacklisted: Boolean
  ) {
    tenant(id: $tenantID) {
      reports(
        q: $q
        page: $page
        pageSize: $pageSize
        status: $status
        service: $service
        channel: $channel
        blacklisted: $blacklisted
      ) {
        total
        page
        pageSize
        facets {
          id
          total
          terms {
            id
            name
            count
          }
        }
        reports {
          id
          ticket
          serviceID
          serviceName
          shared
          blacklisted
          status
          statusLabel
          statusMessage
          statusUpdatedAt
          openedAt
          closedAt
          description
          channel
          submittedPhoto
          closedPhoto
          latitude
          longitude
          address
        }
      }
    }
  }
`;
