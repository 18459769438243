import { Typography } from "@material-ui/core";
import * as React from "react";

interface Props {
  foregroundColor: string;
  backgroundColor: string;
}

const itemStyle = {
  display: "inline-block",
  fontSize: 12,
  fontWeight: 400,
  paddingLeft: 4,
  paddingRight: 4,
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: "5px/2px",
};

export default function Box(props: React.PropsWithChildren<Props>) {
  return (
    <Typography
      style={Object.assign({}, itemStyle, {
        color: props.foregroundColor,
        backgroundColor: props.backgroundColor,
      })}
      variant="body2"
    >
      {props.children}
    </Typography>
  );
}
