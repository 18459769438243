import { IconButton, Popover, Tooltip } from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import FilterIcon from "@material-ui/icons/FilterList";
import * as React from "react";

const label = "Filter";

interface Props {
  disabled?: IconButtonProps["disabled"];
  color?: SvgIconProps["color"];
}

function FilterButton({
  children,
  disabled,
  color
}: React.PropsWithChildren<Props>) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const showFilters = Boolean(anchorEl);
  const id = showFilters ? "simple-popover" : undefined;

  function handleClick(event: React.MouseEvent) {
    setAnchorEl(event.currentTarget);
  }

  function handleHideForm() {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <Tooltip title={label}>
        <>
          <IconButton
            aria-label={label}
            onClick={handleClick}
            className={classes.button}
            disabled={disabled}
          >
            <FilterIcon color={color} />
          </IconButton>
        </>
      </Tooltip>
      <Popover
        id={id}
        open={showFilters}
        anchorEl={anchorEl}
        onClose={handleHideForm}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {children}
      </Popover>
    </React.Fragment>
  );
}

const useStyles = makeStyles(_theme => ({
  button: {}
}));

export default FilterButton;
