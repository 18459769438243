import * as Colors from "@material-ui/core/colors";
import { Color } from "csstype";
import * as React from "react";
import { Box } from "../../components";

export const foregroundMap: { [key: string]: Color } = {
  success: Colors.green[500],
  warning: Colors.amber[900],
  error: Colors.red[500],
  open: Colors.green[500],
  submitted: Colors.blueGrey[500],
  closed: Colors.blue[500],
};

const backgroundMap: { [key: string]: Color } = {
  success: Colors.green[50],
  warning: Colors.amber[100],
  error: Colors.red[50],
  open: Colors.green[50],
  submitted: Colors.blueGrey[50],
  closed: Colors.blue[50],
};

interface Props {
  status: string;
  label?: string | null;
}

export function StatusBadge({ status, label }: Props) {
  return renderChip(status, label);
}

function renderChip(status: string, label?: string | null) {
  const title = label ?? status;
  const color = foregroundMap[status] ?? Colors.blueGrey[500];
  const backgroundColor = backgroundMap[status] ?? Colors.blueGrey[50];
  return (
    <Box foregroundColor={color} backgroundColor={backgroundColor}>
      {title.toUpperCase()}
    </Box>
  );
}

export default StatusBadge;
