import { IconButton, Tooltip } from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import * as React from "react";

const label = "Search";

interface Props {
  disabled?: IconButtonProps["disabled"];
  onClick?: IconButtonProps["onClick"];
}

function SearchButton({ disabled, onClick }: Props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Tooltip title={label}>
        <>
          <IconButton
            aria-label={label}
            onClick={onClick}
            className={classes.button}
            disabled={disabled}
          >
            <SearchIcon />
          </IconButton>
        </>
      </Tooltip>
    </React.Fragment>
  );
}

const useStyles = makeStyles(_theme => ({
  button: {}
}));

export default SearchButton;
