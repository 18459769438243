import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#76a7dc",
      main: "#4378aa",
      dark: "#004c7b"
    },
    secondary: {
      light: "#ffd65f",
      main: "#e0a52c",
      dark: "#aa7600"
    },
    error: {
      main: red[500],
      dark: red[700]
    }
  }
});

export default theme;
