import Avatar from "@material-ui/core/Avatar";
import { CSSProperties } from "@material-ui/styles";
import * as _ from "lodash";
import * as React from "react";

const defaultSize = 24;
const fontDecrease = 2;

const defaultColors = [
  "#2ecc71", // emerald
  "#3498db", // peter river
  "#8e44ad", // wisteria
  "#e67e22", // carrot
  "#e74c3c", // alizarin
  "#1abc9c", // turquoise
  "#2c3e50", // midnight blue
];

const getInitials = (name?: string) => {
  let initials = "";
  if (name && _.isString(name)) {
    const nameSplitted = _.chain(name)
      .split(/\s+/g)
      .filter((word) => word.length > 0)
      .take(3)
      .value();
    _.each(nameSplitted, (str) => {
      initials += str[0];
    });
  }

  return _.toUpper(initials);
};

const sumChars = (str: string) => {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }
  return sum;
};

interface Props {
  name?: string;
  initials?: string;
  color?: string;
  icon?: string;
  tint?: boolean;
  avatar?: string;
  size?: number;
}

export function Badgatar(props: Props) {
  const { name, initials, color, tint, icon, avatar, size } = props;
  const theSize = size ?? defaultSize;
  const fontSize = (theSize ?? 24) / fontDecrease;

  if (icon || avatar) {
    const imageStyle: CSSProperties = { width: theSize, height: theSize };
    if (tint) {
      imageStyle.opacity = 0.3;
    }
    return <Avatar alt={name} src={icon ?? avatar} style={imageStyle} />;
  } else {
    let theInitials = initials;
    if (!theInitials) {
      theInitials = getInitials(name);
    }
    let theColor = color;
    if (!theColor) {
      const i = sumChars(theInitials) % defaultColors.length;
      theColor = defaultColors[i];
    }
    return (
      <Avatar
        alt={name}
        style={{
          width: theSize,
          height: theSize,
          fontSize,
          fontWeight: "bold",
          color: "#fff",
          backgroundColor: theColor,
        }}
      >
        {theInitials.toUpperCase()}
      </Avatar>
    );
  }
}

export default Badgatar;
