export const formatToUnits = (count: number): string => {
  const precision = 1;
  if (count < 10000) {
    return count.toLocaleString();
  }
  const abbrev = ["", "k", "m", "b", "t"];
  const unrangifiedOrder = Math.floor(Math.log10(Math.abs(count)) / 3);
  const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1));
  const suffix = abbrev[order];
  const result = (count / Math.pow(10, order * 3)).toFixed(precision);

  return result.endsWith(".0")
    ? result.substring(0, result.length - 2) + suffix
    : result + suffix;
};
