import * as Colors from "@material-ui/core/colors";
import * as React from "react";
import { Tenant } from "../api";
import Box from "./Box";

interface Props {
  tenant: Tenant;
}
export default function EnvironmentChip(props: Props) {
  const env = props.tenant.version3
    ? props.tenant.status?.env
    : props.tenant.status?.environment;
  if (env) {
    let backgroundColor: string = Colors.grey[50];
    let foregroundColor: string = Colors.grey[900];
    if (env === "production") {
      backgroundColor = Colors.blue[50];
      foregroundColor = Colors.blue[900];
    } else if (env === "staging") {
      backgroundColor = Colors.yellow[50];
      foregroundColor = Colors.yellow[900];
    }
    return (
      <Box foregroundColor={foregroundColor} backgroundColor={backgroundColor}>
        {env.toUpperCase()}
      </Box>
    );
  }
  return null;
}
