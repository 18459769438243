import { Typography } from "@material-ui/core";
import * as moment from "moment";
import * as React from "react";

const weekMils = 604800000;
const dayMils = 86400000;
const hourMils = 3600000;
const minMils = 60000;

interface Props {
  interval: number;
  time: Date | string;
  twitter: boolean;
  hideAgo: boolean;
}

interface State {
  agoString: string;
}

export default class TimeAgo extends React.PureComponent<Props, State> {
  public static defaultProps = {
    hideAgo: false,
    interval: 60000,
    twitter: false,
  };

  private scheduleNextTimer = false;

  constructor(props: Props) {
    super(props);
    this.state = { agoString: this.getTimeAgo() };
  }

  public componentWillMount() {
    this.scheduleNextTimer = true;
  }

  public componentDidMount() {
    this.createTimer();
  }

  public componentWillUnmount() {
    this.scheduleNextTimer = false;
  }

  public render() {
    return (
      <Typography variant="body2" color="textSecondary">
        {this.state.agoString}
      </Typography>
    );
  }

  private createTimer = () => {
    setTimeout(this.update, this.props.interval);
  };

  private update = () => {
    if (this.scheduleNextTimer) {
      this.setState({ agoString: this.getTimeAgo() });
      this.createTimer();
    }
  };

  private getTimeAgo() {
    const { time, twitter, hideAgo } = this.props;
    if (twitter) {
      const thisMoment = moment(time);
      if (thisMoment.toDate().getTime() === 0) {
        return "epoch";
      }
      let diff = thisMoment.diff(moment());
      diff = Math.abs(diff);
      if (diff > weekMils) {
        return `${Math.round(diff / weekMils)} w`;
      } else if (diff > dayMils) {
        return `${Math.round(diff / dayMils)} d`;
      } else if (diff > hourMils) {
        return `${Math.round(diff / hourMils)} h`;
      } else if (diff > minMils) {
        return `${Math.round(diff / minMils)} m`;
      } else {
        return "just now";
      }
    } else {
      return moment(time).fromNow(hideAgo);
    }
  }
}
