import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Report } from "../../api";
import { Badgatar, StatusBadge, TimeAgo } from "../../core";
import ReportTileMedia from "./ReportTileMedia";

export interface Props {
  report: Report;
}

function ReportTile(props: Props) {
  const { report } = props;
  const classes = useStyles();

  return (
    <Box className={classes.card} key={report.ticket ?? undefined}>
      <Badgatar size={32} name={report.serviceName} tint={true} />
      <div className={classes.details}>
        <div className={classes.content}>
          <Typography component="h6" variant="h6" style={{ display: "flex" }}>
            {report.serviceName}
          </Typography>

          {report.address && (
            <Typography variant="subtitle1" color="textSecondary">
              {report.address}
            </Typography>
          )}
        </div>
        {(report.submittedPhoto ||
          report.description ||
          report.closedPhoto) && (
          <div className={classes.media}>
            <ReportTileMedia report={report} />
          </div>
        )}
        <div className={classes.status}>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <StatusBadge status={report.status} label={report.statusLabel} />
            </Grid>
            <Grid item>
              <TimeAgo time={report.statusUpdatedAt} />
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {report.statusMessage}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                #{report.ticket}
              </Typography>
            </Grid>
            {report.channel && (
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  via {report.channel}
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    padding: theme.spacing(2),
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  content: {
    flex: "1 0 auto",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  media: {
    flex: "1 0 auto",
    padding: theme.spacing(1),
  },
  status: {
    padding: theme.spacing(1),
  },
}));

export default ReportTile;
